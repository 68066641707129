import { ForwardedRef, ReactNode } from "react"
import { DeepReadonly, forwardRefAndSetProperties, satAs } from "my-util"
import style from "./style.module.css"

export namespace Flex {
    export interface Props {
        direction?: Direction
        justify?: Justify
        align?: Align
        gap?: string
        wrap?: boolean

        children?: ReactNode

        width?: string
        height?: string
    }

    export type Direction =
        | "column"
        | "row"

    export type Justify =
        | "start"
        | "end"
        | "center"
        | "left"
        | "right"
        | "normal"
        | "space-between"
        | "space-around"
        | "space-evenly"
        | "stretch"

    export type Align =
        | "normal"
        | "center"
        | "start"
        | "end"
        | "stretch"
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Flex = forwardRefAndSetProperties(
    {
        DEFAULT_DIRECTION: satAs<Flex.Direction>("column"),
        DEFAULT_JUSTIFY: satAs<Flex.Justify>("start"),
        DEFAULT_ALIGN: satAs<Flex.Align>("center"),
        DEFAULT_GAP: "16px" as string,
    } as const,

    (
        {
            direction, justify, align, gap, wrap,
            children,
            width, height,
        }: DeepReadonly<Flex.Props>,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const innerDirection = direction ?? Flex.DEFAULT_DIRECTION
        const innerJustify = justify ?? Flex.DEFAULT_JUSTIFY
        const innerAlign = align ?? Flex.DEFAULT_ALIGN
        const innerGap = gap ?? Flex.DEFAULT_GAP

        return <div
            className={style.Flex}
            style={{
                flexDirection: innerDirection,
                justifyContent: innerJustify,
                alignItems: innerAlign,
                gap: innerGap,

                flexWrap: wrap ? "wrap" : "nowrap",

                width, height,
            }}
            ref={ref}
        >
            {children}
        </div>
    },
)

Flex.displayName = "Flex"
