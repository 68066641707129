import getSymbolFromCurrency from "currency-symbol-map"
import Decimal from "decimal.js"

import { ForwardedRef, forwardRef, Fragment, ReactNode,
         useContext, useEffect, useMemo, useRef, useState } from "react"

import { useTranslation } from "react-i18next"
import { fileIconUrl } from "images"
import { getLang } from "i18n"
import { createDocumentDownloadUrlById, getDocumentById } from "api"

import { getTransferGroup, TransferStatus,
         Money, Transfer, AGENT_USER_ROLE, User,
         NEW_TRANSFER_STATUS, CLIENT_USER_ROLE, Document,
         DONE_TRANSFER_STATUS, WAITING_MOMENT_TRANSFER_STATUS,
         WAITING_PAYMENT_TRANSFER_STATUS, PAID_TRANSFER_STATUS,
         PAYMENT_EXPIRED_TRANSFER_STATUS, REJECTED_TRANSFER_STATUS } from "model"

import { DeepReadonly, ReadonlyDate, ZERO_DECIMAL,
         formatDecimal, dateToDateString, Comparator } from "my-util"

import { UserContext } from "ui/context"
import { createPath } from "ui/page/sections/users/UserPage/path"
import { UserLink } from "ui/component/user"

import { TransferDirectionIcon, TransferGroupColorLegend, Tooltip,
         Loading, Link, TransferPhaseIndicator, Flex, UserRoleArrow,
         Icon, Clickable, UserRoleAbbr, ErrorDisplay, FlexItem, Sticky } from "ui/ui"

import style from "./style.module.css"

export namespace TransferTable {
    export interface Props {
        onTransferClick?: (transfer: Transfer) => void
        transfers: Transfer[]

        showGroups?: boolean
        showAgents?: boolean

        users?: Map<string, User> | Iterable<User>

        width?: string

        comparator?: Comparator<Transfer>
        toSorted?: (users: readonly Transfer[]) => Transfer[]
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferTable = forwardRef((
    {
        onTransferClick, transfers,
        showGroups, showAgents,
        users,
        width,
        comparator, toSorted,
    }: DeepReadonly<TransferTable.Props>,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    // Refs

    const tableRef = useRef<HTMLTableElement | null>(null)

    // State

    const [documentsById, setDocumentsById] = useState(new Map<string, Document>())
    const [loadingDocumentIds, setLoadingDocumentIds] = useState(new Set<string>())
    const [documentLoadingErrorsById, setDocumentLoadingErrorsById] = useState(new Map<string, unknown>())

    const usersById = useMemo(() => User.groupByIdOrPassOrCreate(users), [users])

    const innerTransfers = useMemo(
        () => {
            if (toSorted != null)
                return toSorted(transfers)

            if (comparator != null)
                return ([...transfers]).sort(comparator)

            return ([...transfers]).sort((lhs, rhs) => {
                const lhsCreator = lhs.creatorId != null
                    ? usersById.get(lhs.creatorId)
                    : undefined

                const rhsCreator = rhs.creatorId != null
                    ? usersById.get(rhs.creatorId)
                    : null

                if (lhsCreator == null && rhsCreator == null)
                    return lhs.country.localeCompare(rhs.country)

                if (lhsCreator == null)
                    return -1

                if (rhsCreator == null)
                    return 1

                const lhsApplicant = lhsCreator.company.anyName ?? lhsCreator.name
                const rhsApplicant = rhsCreator.company.anyName ?? rhsCreator.name

                return lhsApplicant.localeCompare(rhsApplicant)
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [usersById, transfers, comparator, toSorted, getLang()],
    )

    const [selectedTransferId, setSelectedTransferId] = useState<string | null>(null)

    const selectedTransfer = useMemo(
        () => innerTransfers.find(({ id }) => id === selectedTransferId),
        [innerTransfers, selectedTransferId],
    )

    // Effects

    useEffect(
        () => {
            const table = tableRef.current

            if (table == null)
                return

            document.addEventListener("click", handleClick)

            return () => document.removeEventListener("click", handleClick)

            function handleClick(event: MouseEvent) {
                if (!(event.target instanceof Node) || !table!.contains(event.target))
                    setSelectedTransferId(null)
            }
        },

        [],
    )

    // Render

    return <Flex direction="row"

                 align="stretch"

                 gap="0"

                 ref={ref}>
        {renderTable()}
        {renderPhases()}
    </Flex>

    // - Tables

    function renderTable(): ReactNode {
        return <FlexItem grow={0}>
            <table className={style.table}
                   ref={tableRef}
                   width={width}>
                <tbody>
                    {innerTransfers.map(transfer =>
                        showAgents
                            ? renderTableWithAgent(transfer)
                            : renderTableWithoutAgent(transfer)
                    )}
                </tbody>
            </table>
        </FlexItem>
     }

    function renderTableWithAgent(transfer: Transfer): ReactNode {
        const onRowClick = () => onInnerTransferClick(transfer)

        const selected = selectedTransferId === transfer.id

        const creator = transfer.creatorId != null
            ? usersById.get(transfer.creatorId)
            : undefined

        const agent = creator?.creatorId != null
            ? usersById.get(creator.creatorId)
            : undefined

        const documentIds = transfer.allDocumentIds

        return <Fragment key={transfer.id}>
            {/* First row */}

            <tr className={selected ? style.selectedFirstRow : style.firstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.group}>
                        <TransferGroupColorLegend group={getTransferGroup(transfer)}/>
                    </td>
                }

                <td className={style.company}
                    colSpan={3}>
                    {renderUserCompany(creator)}
                </td>

                <td>{renderDirectionWithCountry(transfer)}</td>
                <td>{t("domain.transfers.labels.date") + ":"}</td>
                <td>{renderStatus(transfer.status)}</td>

                <td className={style.roleArrow}>
                    {selected &&
                        <UserRoleArrow role={localUser?.role}/>
                    }
                </td>
            </tr>

            {/* Second row */}

            <tr className={style.notFirstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.client}/>
                }

                <td className={style.client + " " + style.roleAbbr}>
                    <UserRoleAbbr role={CLIENT_USER_ROLE}
                                  colorful/>
                </td>

                <td className={style.client}>
                    {renderUser(creator)}
                </td>

                <td className={style.client}/>
                <td>{renderMoney(transfer.money)}</td>
                <td>{renderMoment(transfer.moment)}</td>
                <td colSpan={2}/>
            </tr>

            {/* Third row */}

            <tr className={style.notFirstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.agent}/>
                }

                <td className={style.agent + " " + style.roleAbbr}>
                    <UserRoleAbbr role={AGENT_USER_ROLE}
                                  colorful/>
                </td>

                <td className={style.agent}>
                    {renderUser(agent)}
                </td>

                <td className={style.agent}>
                    {renderAgentPercent(transfer.agentPercent)}
                </td>

                <td>{renderCurrencyRate(transfer)}</td>
                <td>{transfer.cost?.toString(4)}</td>
                <td colSpan={2}/>
            </tr>

            {/* Fourth row */}

            <tr className={style.notFirstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.documents}/>
                }

                <td className={style.documents}/>

                <td className={style.documents}
                    colSpan={2}>
                    {renderDocuments(documentIds)}
                </td>

                <td colSpan={4}/>
            </tr>

            {/* Gap */}

            <tr className={style.gap}/>
        </Fragment>
    }

    function renderTableWithoutAgent(transfer: Transfer): ReactNode {
        const onRowClick = () => onInnerTransferClick(transfer)

        const selected = selectedTransferId === transfer.id

        return <Fragment key={transfer.id}>
            {/* First row */}

            <tr className={selected ? style.selectedFirstRow : style.firstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.group}>
                        <TransferGroupColorLegend group={getTransferGroup(transfer)}/>
                    </td>
                }

                <td className={style.country}>
                    {renderDirectionWithCountry(transfer)}
                </td>

                <td>{t("domain.transfers.labels.date") + ":"}</td>
                <td>{renderStatus(transfer.status)}</td>

                <td className={style.roleArrow}>
                    {selected &&
                        <UserRoleArrow role={localUser?.role}/>
                    }
                </td>
            </tr>

            {/* Second row */}

            <tr className={style.notFirstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.agent}/>
                }

                <td className={style.agent}>
                    {renderMoney(transfer.money)}
                </td>

                <td>{renderMoment(transfer.moment)}</td>
                <td colSpan={2}/>
            </tr>

            {/* Third row */}

            <tr className={style.notFirstRow}
                onClick={onRowClick}>
                {showGroups &&
                    <td className={style.documents}/>
                }

                <td className={style.documents}>
                    {renderDocuments(transfer.allDocumentIds)}
                </td>

                <td colSpan={3}/>
            </tr>

            {/* Gap row */}

            <tr className={style.gap}/>
        </Fragment>
    }

    // -- Parts

    function renderStatus(status: TransferStatus): ReactNode {
        switch (status) {
            case NEW_TRANSFER_STATUS:
                return prefixStatus(1)

            case WAITING_MOMENT_TRANSFER_STATUS:
                return prefixStatus(2)

            case WAITING_PAYMENT_TRANSFER_STATUS:
                return prefixStatus(3)

            case PAID_TRANSFER_STATUS:
                return prefixStatus(4)

            case DONE_TRANSFER_STATUS:
                return <span className={style.doneStatus}>
                    {t("domain.transfers.statuses.done")}
                </span>

            case PAYMENT_EXPIRED_TRANSFER_STATUS:
            case REJECTED_TRANSFER_STATUS:
                return <span className={style.declinedStatus}>
                    {t("domain.transfers.groups.declined")}
                </span>
        }

        function prefixStatus(status: ReactNode): ReactNode {
            return <span className={style.status}>
                {t("domain.transfers.labels.status")}
                {": "}
                {status}
            </span>
        }
    }

    function renderUserCompany(user?: User | null): ReactNode {
        if (user == null)
            return null

        const name = user.company?.anyName

        if (name == null)
            return t("domain.companies.messages.notSet")

        return <Link to={createPath(user.id)}
                     text={name}
                     stopClickPropagation/>
    }

    function renderDirectionWithCountry(transfer: Transfer): ReactNode {
        return <Flex direction="row"
                     gap="4px">
            <div className={style.directionIcon}>
                <TransferDirectionIcon direction={transfer.direction}
                                       width="8px"
                                       height="20px"/>
            </div>

            {transfer.country}
        </Flex>
    }

    function renderUser(user?: User | null): ReactNode {
        return user != null
            ? <UserLink stopClickPropagation
                        user={user}/>

            : "-"
    }

    function renderAgentPercent(percent?: Decimal | null): ReactNode {
        return `${formatDecimal(percent ?? ZERO_DECIMAL, 4)}%`
    }

    function renderMoney(money: DeepReadonly<Money>): ReactNode {
        return <>
            {getSymbolFromCurrency(money.currency)}
            {" "}
            {formatDecimal(money.amount)}
        </>
    }

    function renderMoment(moment?: ReadonlyDate | null): ReactNode {
        return moment != null
            ? dateToDateString(moment)
            : t("domain.transfers.messages.dateNotSet")
    }

    function renderCurrencyRate(transfer: Transfer): ReactNode {
        const { currencyRate } = transfer

        if (currencyRate == null)
            return

        return <>
            {currencyRate.money.toString(4)}
            {"/"}
            {getSymbolFromCurrency(transfer.money.currency)}
        </>
    }

    function renderDocuments(documentIds: readonly string[]): ReactNode {
        return <Flex direction="row"
                     wrap>
            {documentIds.map(id =>
                <div style={{ position: "relative" }}
                     key={id}>
                    <Clickable stopPropagation>
                        {renderDocument(id)}
                    </Clickable>

                    <Tooltip onShow={shown => onShow(id, shown)}>
                        {renderTooltipDocument(id)}
                    </Tooltip>
                </div>
            )}
        </Flex>

        function renderDocument(documentId: string): ReactNode {
            const document = documentsById.get(documentId)

            if (document == null)
                return renderDocumentIcon()

            return <Link to={createDocumentDownloadUrlById(document.id)}
                         download={document.name}
                         target="_blank">
                {renderDocumentIcon()}
            </Link>
        }

        function renderDocumentIcon(): ReactNode {
            return <Icon height="20px"
                         width="fit-content"

                         src={fileIconUrl}
                         alt="File icon"
                         filter="brightness(0)"/>
        }

        function renderTooltipDocument(documentId: string): ReactNode {
            const document = documentsById.get(documentId)

            if (document != null)
                return document.name

            const error = documentLoadingErrorsById.get(documentId)

            if (error != null)
                return <ErrorDisplay centerType="flex"
                                     error={error}/>

            return <Loading centerType="flex"/>
        }

        async function onShow(documentId: string, shown: boolean) {
            if (!shown ||
                documentsById.has(documentId) ||
                loadingDocumentIds.has(documentId) ||
                documentLoadingErrorsById.has(documentId))
                return

            setLoadingDocumentIds(oldLoadingDocumentIds => {
                const newLoadingDocumentIds = new Set(oldLoadingDocumentIds)
                newLoadingDocumentIds.add(documentId)
                return newLoadingDocumentIds
            })

            try {
                const document = await getDocumentById(documentId)

                setDocumentsById(oldDocumentsById => {
                    const newDocumentsById = new Map(oldDocumentsById)
                    newDocumentsById.set(document.id, document)
                    return newDocumentsById
                })
            } catch (error) {
                setDocumentLoadingErrorsById(oldDocumentLoadingErrorsById => {
                    const newDocumentLoadingErrorsById = new Map(oldDocumentLoadingErrorsById)
                    newDocumentLoadingErrorsById.set(documentId, error)
                    return newDocumentLoadingErrorsById
                })
            } finally {
                setLoadingDocumentIds(oldLoadingDocumentIds => {
                    const newLoadingDocumentIds = new Set(oldLoadingDocumentIds)
                    newLoadingDocumentIds.delete(documentId)
                    return newLoadingDocumentIds
                })
            }
        }
    }

    // - Phases

    function renderPhases(): ReactNode {
        if (selectedTransfer == null)
            return null

        return <FlexItem grow={1}>
            <Sticky top={16}
                    gap={16}>
                <TransferPhaseIndicator status={selectedTransfer.status}
                                        indicatorStyle="mini"
                                        direction="column"/>
            </Sticky>
        </FlexItem>
    }

    // Events

    function onInnerTransferClick(transfer: Transfer) {
        if (selectedTransferId === transfer.id) {
            onTransferClick?.(transfer)
            return
        }

        setSelectedTransferId(transfer.id)
    }
})

TransferTable.displayName = "TransferTable"
