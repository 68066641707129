import { useEffect, useMemo, useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { getMe } from "api"
import { User } from "model"
import { UserContext } from "ui/context"
import * as page from "ui/page"
import { Loading } from "ui/ui"

export function App() {
    // State

    const [loadingLocalUser, setLoadingLocalUser] = useState(true)
    const [localUser, setLocalUser] = useState<User | null>(null)
    const refetchUser = () => setLoadingLocalUser(true)

    const isInDevelopment = useMemo(() => process.env.NODE_ENV === "development", [])
    const [testModule, setTestModule] = useState<typeof import("ui/page/test") | null>(null)
    const [loadingTestModule, setLoadingTestModule] = useState(isInDevelopment && testModule == null)

    const loading = loadingLocalUser || loadingTestModule

    // Effects

    // - Local user loading

    useEffect(
        () => {
            if (!loadingLocalUser)
                return

            const controller = new AbortController()

            getMe(controller.signal)
                .then(setLocalUser)
                .catch(error => {
                    if (controller.signal.aborted)
                        return

                    setLocalUser(null)
                    console.error(error)
                })
                .finally(() => {
                    if (!controller.signal.aborted)
                        setLoadingLocalUser(false)
                })

            return () => controller.abort()
        },

        [loadingLocalUser],
    )

    // - Test module loading

    useEffect(
        () => {
            if (!loadingTestModule)
                return

            import("ui/page/test")
                .then(setTestModule)
                .catch(error => { setTestModule(null); console.error(error) })
                .finally(() => setLoadingTestModule(false))
        },

        [loadingTestModule],
    )

    // - Drag events handling

    useEffect(
        () => {
            window.addEventListener("dragstart", preventDefault)
            window.addEventListener("dragover", preventDefault)
            window.addEventListener("drop", preventDefault)

            return () => {
                window.removeEventListener("dragstart", preventDefault)
                window.removeEventListener("dragover", preventDefault)
                window.removeEventListener("drop", preventDefault)
            }

            function preventDefault(event: DragEvent) {
                event.preventDefault()
            }
        },

        [],
    )

    // Render

    if (loading)
        return <Loading/>

    return <UserContext.Provider value={[localUser, setLocalUser, refetchUser]}>
        <BrowserRouter>
            <Routes>
                <Route Component={page.MainPage}
                       path={page.MainPage.PATH}/>

                <Route Component={page.MainPage}
                       path={page.MainPage.SECOND_PATH}/>

                <Route Component={page.MainTransfersPage}
                       path={page.MainTransfersPage.PATH}/>

                <Route Component={page.MainInvitesPage}
                       path={page.MainInvitesPage.PATH}/>

                {/* Users */}

                <Route Component={page.AllClientsPage}
                       path={page.AllClientsPage.PATH}/>

                <Route Component={page.AllAgentsPage}
                       path={page.AllAgentsPage.PATH}/>

                <Route Component={page.AllAdminsPage}
                       path={page.AllAdminsPage.PATH}/>

                <Route Component={page.UserPage}
                       path={page.UserPage.PATH}/>

                {/* Invites */}

                <Route Component={page.AllInvitesPage}
                       path={page.AllInvitesPage.PATH}/>


                <Route Component={page.InviteCreationPage}
                       path={page.InviteCreationPage.PATH}/>

                <Route Component={page.InvitePage}
                       path={page.InvitePage.PATH}/>

                <Route Component={page.AllInviteApplicationsPage}
                       path={page.AllInviteApplicationsPage.PATH}/>

                {/* Transfers */}

                <Route Component={page.AllTransfersPage}
                       path={page.AllTransfersPage.PATH}/>

                <Route Component={page.TransferPage}
                       path={page.TransferPage.PATH}/>

                <Route Component={page.TransferCreationPage}
                       path={page.TransferCreationPage.PATH}/>

                <Route Component={page.AllArchivedTransfersPage}
                       path={page.AllArchivedTransfersPage.PATH}/>

                <Route Component={page.ArchivedTransferPage}
                       path={page.ArchivedTransferPage.PATH}/>

                <Route Component={page.WalletPage}
                       path={page.WalletPage.PATH}/>

                {/* Documents */}

                <Route Component={page.AllDocumentsPage}
                       path={page.AllDocumentsPage.PATH}/>

                {/* Calendar */}

                <Route Component={page.CalendarPage}
                       path={page.CalendarPage.PATH}/>

                {/* Contacts */}

                {/* <Route Component={page.ContactsPage}
                       path={page.ContactsPage.PATH}/> */}

                {/* Providers */}

                <Route Component={page.AllProvidersPage}
                       path={page.AllProvidersPage.PATH}/>

                {/* Messenger */}

                <Route Component={page.MessengerPage}
                       path={page.MessengerPage.PATH}/>

                {/* Auth*/}

                <Route Component={page.LoginPage}
                       path={page.LoginPage.PATH}/>

                <Route Component={page.PasswordResetPage}
                       path={page.PasswordResetPage.PATH}/>

                <Route Component={page.RegistrationPage}
                       path={page.RegistrationPage.PATH}/>

                {/* Test */}

                {testModule != null && <>
                    <Route Component={testModule.MainTestPage}
                           path={testModule.MainTestPage.PATH}/>

                    <Route Component={testModule.ButtonsTestPage}
                           path={testModule.ButtonsTestPage.PATH}/>

                    <Route Component={testModule.DocumentsTestPage}
                           path={testModule.DocumentsTestPage.PATH}/>

                    <Route Component={testModule.RadiosTestPage}
                           path={testModule.RadiosTestPage.PATH}/>
                </>}

                <Route Component={page.Error404Page}
                       path="*"/>
            </Routes>
        </BrowserRouter>
    </UserContext.Provider>
}
