import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { plusIconUrl } from "images"
import { getAllUsersByIds, getAllTransfersByStatusNot, getAllMyTransfersByStatusNot } from "api"

import { Transfer, TransferDirection, DONE_TRANSFER_STATUS, TransferGroup,
         TransferSortBy, DATE_TRANSFER_SORT_BY, getTransferSortByComparator,
         User, IMPORT_TRANSFER_DIRECTION, EXPORT_TRANSFER_DIRECTION, getTransferGroup } from "model"

import { DATE_COMPARATOR, DateRange, findMin, findMax,
         uniqueArray, createOrCopyDate, ReadonlyDate, Comparator } from "my-util"

import { useTransfers, useUsers } from "ui/hook"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import { DownloadTransfersReportLink, Page, TransferTable } from "ui/component"

import { TransferDirectionRadio, Button, Padding, Flex,
         TransferGroupColorLegendList, ErrorDisplay, Loading,
         Label, TransferSortBySelect, CheckBox, DateRangeInput } from "ui/ui"

import * as TransferPage from "../TransferPage/path"
import * as TransferCreationPage from "../TransferCreationPage/path"

export function Component() {
    const [t] = useTranslation()

    const [localUser] = useContext(UserContext)

    const storedUsers = useUsers()
    const storedTransfers = useTransfers()

    const navigate = useNavigate()

    // State

    // - Users

    const [creators, setCreators] = useState<User[]>([])
    const [loadingCreators, setLoadingCreators] = useState(false)

    const [agents, setAgents] = useState<User[]>([])
    const [loadingAgents, setLoadingAgents] = useState(false)

    const usersById = useMemo(
        () => User.groupById([...creators, ...agents]),
        [creators, agents],
    )

    // - Transfers

    const [transfers, setTransfers] = useState<Transfer[]>([])
    const [loadingTransfers, setLoadingTransfers] = useState(localUser != null)

    // -- Direction filtering

    const importTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === IMPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const exportTransfers = useMemo(
        () => transfers.filter(({ direction }) => direction === EXPORT_TRANSFER_DIRECTION),
        [transfers],
    )

    const [transferDirection, setTransferDirection] = useState<TransferDirection>(
        IMPORT_TRANSFER_DIRECTION
    )

    const directionTransfers = useMemo(
        () => {
            switch (transferDirection) {
                case IMPORT_TRANSFER_DIRECTION:
                    return importTransfers

                case EXPORT_TRANSFER_DIRECTION:
                    return exportTransfers
            }
        },

        [importTransfers, exportTransfers, transferDirection],
    )

    // -- Group filtering

    const [transferGroup, setTransferGroup] = useState<TransferGroup | undefined>(undefined)

    const groupTransfers = useMemo(
        () => transferGroup != null
            ? directionTransfers.filter(transfer => transferGroup === getTransferGroup(transfer))
            : directionTransfers,

        [directionTransfers, transferGroup],
    )

    // -- Moment filtering

    const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined)

    const shownTransfers = useMemo(
        () => groupTransfers.filter(({ moment }) => {
            if (moment == null || dateRange == null)
                return true

            if (moment < dateRange.from)
                return false

            if (moment > dateRange.to)
                return false

            return true
        }),

        [groupTransfers, dateRange],
    )

    const shownTransferIds = useMemo(
        () => shownTransfers.map(({ id }) => id),
        [shownTransfers],
    )

    // -- Sorting

    const [sortTransfersBy, setSortTransferBy] = useState<TransferSortBy>(DATE_TRANSFER_SORT_BY)
    const [reverseTransferSorting, setReverseTransferSorting] = useState(false)

    const transferComparator = useMemo<Comparator<Transfer>>(
        () => getTransferSortByComparator(sortTransfersBy, {
            reverse: reverseTransferSorting,
            users: usersById,
        }),

        [reverseTransferSorting, sortTransfersBy, usersById],
    )

    // - Loading

    const loading =
        loadingTransfers ||
        loadingCreators ||
        loadingAgents

    const [error, setError] = useState<unknown>(undefined)

    // Effects

    // - Transfers loading

    useEffect(() => {
        if (!loadingTransfers)
            return

        const controller = new AbortController()

        const promise = localUser?.isManager
            ? getAllTransfersByStatusNot(DONE_TRANSFER_STATUS, controller.signal)
            : getAllMyTransfersByStatusNot(DONE_TRANSFER_STATUS, controller.signal)

        promise
            .then(transfers => {
                storedTransfers.addAll(transfers)

                setTransfers(transfers)
                setLoadingCreators(localUser?.isManager ?? false)

                const moments = transfers
                    .map(({ moment }) => moment)
                    .filter(moment => moment != null) as ReadonlyDate[]

                setDateRange({
                    from: createOrCopyDate(findMin(moments, DATE_COMPARATOR)),
                    to: createOrCopyDate(findMax(moments, DATE_COMPARATOR)),
                })
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingTransfers(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingTransfers])

    // - Creators loading

    useEffect(() => {
        if (!loadingCreators)
            return

        const creatorIds = uniqueArray(
            transfers
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (creatorIds.length === 0) {
            setLoadingCreators(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: creatorIds }, controller.signal)
            .then(creators => {
                storedUsers.addAll(creators)
                setCreators(creators)
                setLoadingAgents(true)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingCreators(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCreators])

    // - Agents loading

    useEffect(() => {
        if (!loadingAgents)
            return

        const agentIds = uniqueArray(
            creators
                .map(({ creatorId }) => creatorId)
                .filter(id => id != null) as string[]
        )

        if (agentIds.length === 0) {
            setLoadingAgents(false)
            return
        }

        const controller = new AbortController()

        getAllUsersByIds({ ids: agentIds }, controller.signal)
            .then(agents => {
                storedUsers.addAll(agents)
                setAgents(agents)
            })
            .catch(error => {
                if (!controller.signal.aborted)
                    setError(error)
            })
            .finally(() => {
                if (!controller.signal.aborted)
                    setLoadingAgents(false)
            })

        return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAgents])

    // Render

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page compactTitle={renderCompactTitle}
                 title={renderTitle()}
                 autoMoveCompactTitle
                 type="main">
        {renderContent()}
    </Page>

    function renderCompactTitle(compact?: boolean): ReactNode {
        const content = renderContent()

        return compact || loading || transfers.length === 0
            ? content

            : <Padding paddingLeft="32px">
                {content}
            </Padding>

        function renderContent(): ReactNode {
            return <Flex direction="row"
                         gap="8px">
                <Button onClick={() => navigate(TransferCreationPage.PATH)}
                        iconSrc={plusIconUrl}
                        iconAlt="Plus icon"
                        width="32px"/>

                <div style={{ height: "1em" }}>
                    {(
                        compact
                            ? t("sections.transfers.header.short")
                            : t("sections.transfers.header.full")
                    ).toLocaleUpperCase()}
                </div>
            </Flex>
        }
    }

    function renderTitle(): ReactNode {
        if (loading || transfers.length === 0)
            return null

        return <Padding paddingLeft="14px">
            <Flex justify="space-between"
                  direction="row"
                  gap="32px">
                <Flex direction="row"
                      width="fit-content"
                      gap="32px">
                    <TransferDirectionRadio onChange={setTransferDirection}
                                            checked={transferDirection}
                                            width="fit-content"/>

                    <DateRangeInput onChange={setDateRange}
                                    value={dateRange}/>

                    {localUser?.isManager &&
                        <Flex width="fit-content"
                              direction="row"
                              gap="8px">
                            <Label text={t("misc.labels.sortBy")}/>

                            <TransferSortBySelect onSelect={setSortTransferBy}
                                                  selected={sortTransfersBy}
                                                  width="min"/>

                            <CheckBox onChange={setReverseTransferSorting}
                                      checked={reverseTransferSorting}
                                      label={t("misc.labels.reverseSortingOrder")}/>
                        </Flex>
                    }
                </Flex>

                {localUser?.isManager &&
                    <DownloadTransfersReportLink ids={shownTransferIds}
                                                 whiteSpace="nowrap"
                                                 fontSize="16px"/>
                }
            </Flex>
        </Padding>
    }

    function renderContent(): ReactNode {
        if (loading)
            return <Loading/>

        if (error != null)
            return <ErrorDisplay error={error}/>

        if (transfers.length === 0)
            return <Padding paddingTop="22px">
                <div style={{ whiteSpace: "pre" }}>
                    {localUser?.isManager
                        ? t("sections.transfers.messages.no")
                        : t("sections.transfers.messages.noYours")
                    }
                </div>
            </Padding>

        return <Flex align="start"
                     gap="30px">
            <Padding paddingLeft="36px"
                     paddingTop="24px">
                <TransferGroupColorLegendList onClick={onTransferGroupClick}
                                              active={transferGroup}

                                              fontStyle="italic"
                                              fontSize="12px"

                                              width="100%"

                                              showLegend
                                              hideDone/>
            </Padding>

            <TransferTable onTransferClick={onTransferClick}
                           transfers={shownTransfers}
                           comparator={transferComparator}

                           users={usersById}

                           showAgents={localUser?.isManager}
                           showGroups/>
        </Flex>
    }

    // Events

    function onTransferGroupClick(group: TransferGroup) {
        setTransferGroup(oldGroup =>
            group === oldGroup
                ? undefined
                : group
        )
    }

    function onTransferClick(transfer: Transfer) {
        navigate(TransferPage.createPath(transfer.id))
    }
}
