import { useContext } from "react"
import { Navigate } from "react-router-dom"
import { UserContext } from "ui/context"
import { SessionExpiredErrorPage } from "ui/page/error"
import * as AllTransfersPage from "ui/page/sections/transfers/AllTransfersPage/path"
import { Page } from "ui/component"

export function Component() {
    const [localUser] = useContext(UserContext)

    if (localUser == null)
        return <SessionExpiredErrorPage/>

    return <Page type="main">
        <Navigate to={AllTransfersPage.PATH}
                  replace/>
    </Page>
}
