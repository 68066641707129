import { CSSProperties, ForwardedRef, forwardRef } from "react"
import style from "./style.module.css"

export namespace Icon {
    export interface Props {
        onClick?: () => void

        src?: string
        alt?: string
        filter?: string
        cursor?: CSSProperties["cursor"]

        width?: string
        height?: string
    }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Icon = forwardRef((
    {
        onClick,
        src, alt, filter, cursor,
        width, height,
    }: Readonly<Icon.Props>,
    ref: ForwardedRef<HTMLImageElement>,
) =>
    <img className={style.Icon}
         style={{ cursor, width, height, filter }}

         src={src}
         alt={alt ?? "Icon"}

         onClick={onClick}

         ref={ref}/>
)

Icon.displayName = "Icon"
